import React from 'react';
import "./modal.css";

function Modal(props: any) {
  return (
    <div className='modal-overlay'>
      <div className='modal'>
        {props.children}
        <button className='close-button' onClick={props.onClose}>X</button>
      </div>
    </div>
  );
}


export default Modal;