import React from "react";
import "./bracket.css";
import Tournament, { getGame } from "../models/Tournament";

export const Bracket = (tournament: Tournament) => {

    const winnerTag = (game: number, team: string) => {
        if (getGame(tournament, game)?.winner === team) {
            return "winner";
        }
        return "";
    }

    const teamValue = (game: number, team: string) => {
        const foundGame = getGame(tournament, game);
        if (foundGame) {
            if (team === "team1") {
                return foundGame.team1.seed + " " + foundGame.team1.name;
            } else if (team === "team2") {
                return foundGame.team2.seed + " " + foundGame.team2.name;
            }
        }

        return "";
    }

    return (
        <div>
            <header>
                <ol>
                    <li>Round 1 <br /><span>Mar 19-22</span></li>
                    <li>Round 2 <br /><span>Mar 23-24</span></li>
                    <li>Sweet 16 <br /><span>Mar 28-29</span></li>
                    <li>Elite 8 <br /><span>Mar 30-31</span></li>
                    <li>Final 4 <br /><span>Apr 6-8</span></li>
                    <li>Elite 8 <br /><span>Mar 30-31</span></li>
                    <li>Sweet 16<br /><span>Mar 28-29</span></li>
                    <li>Round 2 <br /><span>Mar 23-24</span></li>
                    <li>Round 1 <br /><span>Mar 19-22</span></li>
                </ol>
            </header>
            <div className="bracket">
                <div className="region region-1">
                    <ul className="matchup matchup-1">
                        <li className={"team team-top " + winnerTag(1, "team1")}>{teamValue(1, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(1, "team2")}>{teamValue(1, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-2">
                        <li className={"team team-top " + winnerTag(2, "team1")}>{teamValue(2, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(2, "team2")}>{teamValue(2, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-3">
                        <li className={"team team-top " + winnerTag(3, "team1")}>{teamValue(3, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(3, "team2")}>{teamValue(3, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-4">
                        <li className={"team team-top " + winnerTag(4, "team1")}>{teamValue(4, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(4, "team2")}>{teamValue(4, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-5">
                        <li className={"team team-top " + winnerTag(5, "team1")}>{teamValue(5, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(5, "team2")}>{teamValue(5, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-6">
                        <li className={"team team-top " + winnerTag(6, "team1")}>{teamValue(6, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(6, "team2")}>{teamValue(6, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-7">
                        <li className={"team team-top " + winnerTag(7, "team1")}>{teamValue(7, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(7, "team2")}>{teamValue(7, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-8">
                        <li className={"team team-top " + winnerTag(8, "team1")}>{teamValue(8, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(8, "team2")}>{teamValue(8, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-33">
                        <li className={"team team-top " + winnerTag(33, "team1")}>{teamValue(33, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(33, "team2")}>{teamValue(33, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-34">
                        <li className={"team team-top " + winnerTag(34, "team1")}>{teamValue(34, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(34, "team2")}>{teamValue(34, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-35">
                        <li className={"team team-top " + winnerTag(35, "team1")}>{teamValue(35, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(35, "team2")}>{teamValue(35, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-36">
                        <li className={"team team-top " + winnerTag(36, "team1")}>{teamValue(36, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(36, "team2")}>{teamValue(36, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-49">
                        <li className={"team team-top " + winnerTag(49, "team1")}>{teamValue(49, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(49, "team2")}>{teamValue(49, "team2")}</li>
                    </ul>
                    <ul className="matchup matchup-50">
                        <li className={"team team-top " + winnerTag(50, "team1")}>{teamValue(50, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(50, "team2")}>{teamValue(50, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-57">
                        <li className={"team team-top " + winnerTag(57, "team1")}>{teamValue(57, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(57, "team2")}>{teamValue(57, "team2")}</li>
                    </ul>

                </div>
                <div className="region-2 region">

                    <ul className="matchup matchup-9">
                        <li className={"team team-top " + winnerTag(9, "team1")}>{teamValue(9, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(9, "team2")}>{teamValue(9, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-10">
                        <li className={"team team-top " + winnerTag(10, "team1")}>{teamValue(10, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(10, "team2")}>{teamValue(10, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-11">
                        <li className={"team team-top " + winnerTag(11, "team1")}>{teamValue(11, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(11, "team2")}>{teamValue(11, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-12">
                        <li className={"team team-top " + winnerTag(12, "team1")}>{teamValue(12, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(12, "team2")}>{teamValue(12, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-13">
                        <li className={"team team-top " + winnerTag(13, "team1")}>{teamValue(13, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(13, "team2")}>{teamValue(13, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-14">
                        <li className={"team team-top " + winnerTag(14, "team1")}>{teamValue(14, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(14, "team2")}>{teamValue(14, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-15">
                        <li className={"team team-top " + winnerTag(15, "team1")}>{teamValue(15, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(15, "team2")}>{teamValue(15, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-16">
                        <li className={"team team-top " + winnerTag(16, "team1")}>{teamValue(16, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(16, "team2")}>{teamValue(16, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-37">
                        <li className={"team team-top " + winnerTag(37, "team1")}>{teamValue(37, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(37, "team2")}>{teamValue(37, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-38">
                        <li className={"team team-top " + winnerTag(38, "team1")}>{teamValue(38, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(38, "team2")}>{teamValue(38, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-39">
                        <li className={"team team-top " + winnerTag(39, "team1")}>{teamValue(39, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(39, "team2")}>{teamValue(39, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-40">
                        <li className={"team team-top " + winnerTag(40, "team1")}>{teamValue(40, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(40, "team2")}>{teamValue(40, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-51">
                        <li className={"team team-top " + winnerTag(51, "team1")}>{teamValue(51, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(51, "team2")}>{teamValue(51, "team2")}</li>
                    </ul>
                    <ul className="matchup matchup-52">
                        <li className={"team team-top " + winnerTag(52, "team1")}>{teamValue(52, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(52, "team2")}>{teamValue(52, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-58">
                        <li className={"team team-top " + winnerTag(58, "team1")}>{teamValue(58, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(58, "team2")}>{teamValue(58, "team2")}</li>
                    </ul>

                </div>
                <div className="region-3 region">

                    <ul className="matchup matchup-17">
                        <li className={"team team-top " + winnerTag(17, "team1")}>{teamValue(17, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(17, "team2")}>{teamValue(17, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-18">
                        <li className={"team team-top " + winnerTag(18, "team1")}>{teamValue(18, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(18, "team2")}>{teamValue(18, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-19">
                        <li className={"team team-top " + winnerTag(19, "team1")}>{teamValue(19, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(19, "team2")}>{teamValue(19, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-20">
                        <li className={"team team-top " + winnerTag(20, "team1")}>{teamValue(20, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(20, "team2")}>{teamValue(20, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-21">
                        <li className={"team team-top " + winnerTag(21, "team1")}>{teamValue(21, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(21, "team2")}>{teamValue(21, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-22">
                        <li className={"team team-top " + winnerTag(22, "team1")}>{teamValue(22, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(22, "team2")}>{teamValue(22, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-23">
                        <li className={"team team-top " + winnerTag(23, "team1")}>{teamValue(23, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(23, "team2")}>{teamValue(23, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-24">
                        <li className={"team team-top " + winnerTag(24, "team1")}>{teamValue(24, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(24, "team2")}>{teamValue(24, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-41">
                        <li className={"team team-top " + winnerTag(41, "team1")}>{teamValue(41, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(41, "team2")}>{teamValue(41, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-42">
                        <li className={"team team-top " + winnerTag(42, "team1")}>{teamValue(42, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(42, "team2")}>{teamValue(42, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-43">
                        <li className={"team team-top " + winnerTag(43, "team1")}>{teamValue(43, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(43, "team2")}>{teamValue(43, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-44">
                        <li className={"team team-top " + winnerTag(44, "team1")}>{teamValue(44, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(44, "team2")}>{teamValue(44, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-53">
                        <li className={"team team-top " + winnerTag(53, "team1")}>{teamValue(53, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(53, "team2")}>{teamValue(53, "team2")}</li>
                    </ul>
                    <ul className="matchup matchup-54">
                        <li className={"team team-top " + winnerTag(54, "team1")}>{teamValue(54, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(54, "team2")}>{teamValue(54, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-59">
                        <li className={"team team-top " + winnerTag(59, "team1")}>{teamValue(59, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(59, "team2")}>{teamValue(59, "team2")}</li>
                    </ul>
                </div>

                <div className="region-4 region">

                    <ul className="matchup matchup-25">
                        <li className={"team team-top " + winnerTag(25, "team1")}>{teamValue(25, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(25, "team2")}>{teamValue(25, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-26">
                        <li className={"team team-top " + winnerTag(26, "team1")}>{teamValue(26, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(26, "team2")}>{teamValue(26, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-27">
                        <li className={"team team-top " + winnerTag(27, "team1")}>{teamValue(27, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(27, "team2")}>{teamValue(27, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-28">
                        <li className={"team team-top " + winnerTag(28, "team1")}>{teamValue(28, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(28, "team2")}>{teamValue(28, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-29">
                        <li className={"team team-top " + winnerTag(29, "team1")}>{teamValue(29, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(29, "team2")}>{teamValue(29, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-30">
                        <li className={"team team-top " + winnerTag(30, "team1")}>{teamValue(30, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(30, "team2")}>{teamValue(30, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-31">
                        <li className={"team team-top " + winnerTag(31, "team1")}>{teamValue(31, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(31, "team2")}>{teamValue(31, "team2")}</li>

                    </ul>

                    <ul className="matchup matchup-32">
                        <li className={"team team-top " + winnerTag(32, "team1")}>{teamValue(32, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(32, "team2")}>{teamValue(32, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-45">
                        <li className={"team team-top " + winnerTag(45, "team1")}>{teamValue(45, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(45, "team2")}>{teamValue(45, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-46">
                        <li className={"team team-top " + winnerTag(46, "team1")}>{teamValue(46, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(46, "team2")}>{teamValue(46, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-47">
                        <li className={"team team-top " + winnerTag(47, "team1")}>{teamValue(47, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(47, "team2")}>{teamValue(47, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-48">
                        <li className={"team team-top " + winnerTag(48, "team1")}>{teamValue(48, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(48, "team2")}>{teamValue(48, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-55">
                        <li className={"team team-top " + winnerTag(55, "team1")}>{teamValue(55, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(55, "team2")}>{teamValue(55, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-56">
                        <li className={"team team-top " + winnerTag(56, "team1")}>{teamValue(56, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(56, "team2")}>{teamValue(56, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-60">
                        <li className={"team team-top " + winnerTag(60, "team1")}>{teamValue(60, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(60, "team2")}>{teamValue(60, "team2")}</li>
                    </ul>
                </div>
                <div className="final-four">
                    <ul className="matchup matchup-61">
                        <li className={"team team-top " + winnerTag(61, "team1")}>{teamValue(61, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(61, "team2")}>{teamValue(61, "team2")}</li>
                    </ul>

                    <ul className="matchup matchup-62">
                        <li className={"team team-top " + winnerTag(62, "team1")}>{teamValue(62, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(62, "team2")}>{teamValue(62, "team2")}</li>
                    </ul>

                    <ul className="matchup championship">
                        <li className={"team team-top " + winnerTag(63, "team1")}>{teamValue(63, "team1")}</li>
                        <li className={"team team-bottom " + winnerTag(63, "team2")}>{teamValue(63, "team2")}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}