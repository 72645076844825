import React, { useState, useCallback, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { PromptInput } from '../components/PromptInput';
import { Bracket } from '../components/Bracket';
import { TournamentStart } from '../models/Tournament';
import { ToastContainer, toast } from 'react-toastify';
import Modal from '../components/Modal';
import "react-toastify/dist/ReactToastify.css";

const websocketUrl = 'wss://ac1vr1iwz5.execute-api.us-east-1.amazonaws.com/production/';

export const MainPage = (props: any) => {
    const [tournament, setTournament] = useState(TournamentStart);
    const [addedRounds, setAddedRounds] = useState<number[]>([])
    const [isGenerating, setIsGenerating] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [bracketId, setBracketId] = useState<string>("");
    const [copied, setCopied] = useState(false);

    const { sendMessage, lastMessage } = useWebSocket(websocketUrl);

    useEffect(() => {
        if (lastMessage !== null) {
            const data = JSON.parse(lastMessage.data);
            if (data.error) {
                console.log('ERROR');
                console.log(data.error);
                setIsGenerating(false);
                toast.error("Error generating bracket. Please try again.");
            } else if (data.bracket_id) {
                console.log('DONE');
                console.log(data.bracket_id);
                setIsGenerating(false);
                setModalOpen(true);
                setBracketId(data.bracket_id);
                props.onLink("https://madnessgpt.com/bracket/" + bracketId)
            } else {
                if (data.round && data.tournament && !addedRounds.includes(data.round)) {
                    console.log(data);
                    setAddedRounds([...addedRounds, data.round]);
                    setTournament({ games: data.tournament });
                }
            }
        }
    }, [lastMessage]);

    const handleOnSubmit = useCallback((value: string) => {
        console.log(value);
        setTournament(TournamentStart);
        setAddedRounds([]);
        setCopied(false);
        setIsGenerating(true);
        setModalOpen(false);
        setBracketId("");
        sendMessage(
            JSON.stringify({
                prompt: value,
            })
        );
    }, [sendMessage]);

    const onCopy = (link: string) => {
        navigator.clipboard.writeText(link);
        setCopied(true);
    }

    return (
        <div>
            <div className='main-content'>
                <div>
                    <p className='title'>Ask AI to pick your bracket winners and losers with a prompt!</p>
                    <PromptInput onSubmit={handleOnSubmit} isDisabled={isGenerating} />
                    {Bracket(tournament)}
                </div>
            </div>
            {isModalOpen && (
                <Modal onClose={() => setModalOpen(false)}>
                    <h2>Bracket Complete!</h2>
                    <div className='copy-to-clipboard-box'>
                        <p className='small-link'>{"https://madnessgpt.com/bracket/" + bracketId}</p>
                        <button
                            className='copy-button'
                            onClick={() => onCopy("https://madnessgpt.com/bracket/" + bracketId)}
                        >
                            {copied ? "Copied!" : "Copy"}
                        </button>
                    </div>
                    <p>Share with friends</p>
                </Modal>
            )}
            <ToastContainer />
        </div>
    );
};

export default MainPage;