import React from "react";

const NoPage = () => {

    return (
        <div>
            <p className="title">404</p>
            <p className="title">Page not found</p>
        </div>
    );
}

export default NoPage;