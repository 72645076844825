import { Team } from './Team';
import { Round } from './Round';

interface Game {
    game: number;
    team1: Team;
    team2: Team;
    winner: string | null;
}

interface Tournament {
    games: Game[];
}

export const getGame = (tournament: Tournament, gameNumber: number) => {
    return tournament.games.find(game => game.game === gameNumber) ?? null;
}

export const getWinner = (game: Game) => {
    return game.winner === 'team1' ? game.team1 : game.team2;
}

export default Tournament;

export const TournamentStart: Tournament = {
    "games": [
        {
            "game": 1,
            "team1": {
                "name": "UConn",
                "seed": 1
            },
            "team2": {
                "name": "Stetson",
                "seed": 16
            },
            "winner": null
        },
        {
            "game": 2,
            "team1": {
                "name": "FAU",
                "seed": 8
            },
            "team2": {
                "name": "Northwestern",
                "seed": 9
            },
            "winner": null
        },
        {
            "game": 3,
            "team1": {
                "name": "Sand Diego St.",
                "seed": 5
            },
            "team2": {
                "name": "UAB",
                "seed": 12
            },
            "winner": null
        },
        {
            "game": 4,
            "team1": {
                "name": "Auburn",
                "seed": 4
            },
            "team2": {
                "name": "Yale",
                "seed": 13
            },
            "winner": null
        },
        {
            "game": 5,
            "team1": {
                "name": "BYU",
                "seed": 6
            },
            "team2": {
                "name": "Duquesne",
                "seed": 11
            },
            "winner": null
        },
        {
            "game": 6,
            "team1": {
                "name": "Illinois",
                "seed": 3
            },
            "team2": {
                "name": "Morehead St.",
                "seed": 14
            },
            "winner": null
        },
        {
            "game": 7,
            "team1": {
                "name": "Washington St.",
                "seed": 7
            },
            "team2": {
                "name": "Drake",
                "seed": 10
            },
            "winner": null
        },
        {
            "game": 8,
            "team1": {
                "name": "Iowa St.",
                "seed": 2
            },
            "team2": {
                "name": "South Dakota St.",
                "seed": 15
            },
            "winner": null
        },
        {
            "game": 9,
            "team1": {
                "name": "North Carolina",
                "seed": 1
            },
            "team2": {
                "name": "Howard/Wagner",
                "seed": 16
            },
            "winner": null
        },
        {
            "game": 10,
            "team1": {
                "name": "Mississippi St.",
                "seed": 8
            },
            "team2": {
                "name": "Michigan St.",
                "seed": 9
            },
            "winner": null
        },
        {
            "game": 11,
            "team1": {
                "name": "Saint Mary's",
                "seed": 5
            },
            "team2": {
                "name": "Grand Canyon",
                "seed": 12
            },
            "winner": null
        },
        {
            "game": 12,
            "team1": {
                "name": "Alabama",
                "seed": 4
            },
            "team2": {
                "name": "Charleston",
                "seed": 13
            },
            "winner": null
        },
        {
            "game": 13,
            "team1": {
                "name": "Clemson",
                "seed": 6
            },
            "team2": {
                "name": "New Mexico",
                "seed": 11
            },
            "winner": null
        },
        {
            "game": 14,
            "team1": {
                "name": "Baylor",
                "seed": 3
            },
            "team2": {
                "name": "Colgate",
                "seed": 14
            },
            "winner": null
        },
        {
            "game": 15,
            "team1": {
                "name": "Dayton",
                "seed": 7
            },
            "team2": {
                "name": "Nevada",
                "seed": 10
            },
            "winner": null
        },
        {
            "game": 16,
            "team1": {
                "name": "Arizona",
                "seed": 2
            },
            "team2": {
                "name": "Long Beach St.",
                "seed": 15
            },
            "winner": null
        },
        {
            "game": 17,
            "team1": {
                "name": "Houston",
                "seed": 1
            },
            "team2": {
                "name": "Longwood",
                "seed": 16
            },
            "winner": null
        },
        {
            "game": 18,
            "team1": {
                "name": "Nebraska",
                "seed": 8
            },
            "team2": {
                "name": "Texas A&M",
                "seed": 9
            },
            "winner": null
        },
        {
            "game": 19,
            "team1": {
                "name": "Wisconsin",
                "seed": 5
            },
            "team2": {
                "name": "James Madison",
                "seed": 12
            },
            "winner": null
        },
        {
            "game": 20,
            "team1": {
                "name": "Duke",
                "seed": 4
            },
            "team2": {
                "name": "Vermont",
                "seed": 13
            },
            "winner": null
        },
        {
            "game": 21,
            "team1": {
                "name": "Texas Tech",
                "seed": 6
            },
            "team2": {
                "name": "NC State",
                "seed": 11
            },
            "winner": null
        },
        {
            "game": 22,
            "team1": {
                "name": "Kentucky",
                "seed": 3
            },
            "team2": {
                "name": "Oakland",
                "seed": 14
            },
            "winner": null
        },
        {
            "game": 23,
            "team1": {
                "name": "Florida",
                "seed": 7
            },
            "team2": {
                "name": "Colorado/Boise St.",
                "seed": 10
            },
            "winner": null
        },
        {
            "game": 24,
            "team1": {
                "name": "Marquette",
                "seed": 2
            },
            "team2": {
                "name": "Western Ky.",
                "seed": 15
            },
            "winner": null
        },
        {
            "game": 25,
            "team1": {
                "name": "Purdue",
                "seed": 1
            },
            "team2": {
                "name": "Grambling/Mont St.",
                "seed": 16
            },
            "winner": null
        },
        {
            "game": 26,
            "team1": {
                "name": "Utah St.",
                "seed": 8
            },
            "team2": {
                "name": "TCU",
                "seed": 9
            },
            "winner": null
        },
        {
            "game": 27,
            "team1": {
                "name": "Gonzaga",
                "seed": 5
            },
            "team2": {
                "name": "McNeese",
                "seed": 12
            },
            "winner": null
        },
        {
            "game": 28,
            "team1": {
                "name": "Kansas",
                "seed": 4
            },
            "team2": {
                "name": "Samford",
                "seed": 13
            },
            "winner": null
        },
        {
            "game": 29,
            "team1": {
                "name": "South Carolina",
                "seed": 6
            },
            "team2": {
                "name": "Oregon",
                "seed": 11
            },
            "winner": null
        },
        {
            "game": 30,
            "team1": {
                "name": "Creighton",
                "seed": 3
            },
            "team2": {
                "name": "Akron",
                "seed": 14
            },
            "winner": null
        },
        {
            "game": 31,
            "team1": {
                "name": "Texas",
                "seed": 7
            },
            "team2": {
                "name": "Colorado St./Virginia",
                "seed": 10
            },
            "winner": null
        },
        {
            "game": 32,
            "team1": {
                "name": "Tennessee",
                "seed": 2
            },
            "team2": {
                "name": "Sain Peter's",
                "seed": 15
            },
            "winner": null
        }
    ]
}