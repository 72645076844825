import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import BracketApi from "../api/bracket";
import Tournament from "../models/Tournament";
import { Bracket } from '../components/Bracket';

const BracketPage = (props: any) => {
    const { id } = useParams();
    const [prompt, setPrompt] = useState('');
    const [bracket, setBracket] = useState<Tournament | null>(null);

    useEffect(() => {
        if (id) {
            BracketApi.get(id)
                .then(bracket => {
                    if (bracket) {
                        setPrompt(bracket.prompt);
                        setBracket(bracket.bracket);
                        props.onLink("https://madnessgpt.com/bracket/" + id)
                    }
                });
        }
    }, [id]);

    const bracketView = () => {
        if (bracket != null) {
            return Bracket(bracket);
        }
    }

    return (
        <div>
            <p className="title">{prompt}</p>
            {bracketView()}
        </div>
    );
};

export default BracketPage;