import React, { useState } from 'react';
import "./prompt.css";

export const PromptInput = (props: any) => {
    const [value, setValue] = useState('');

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        props.onSubmit(value);
    };

    const Spinner = (
        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    )

    return (
        <form className='prompt-form' onSubmit={handleSubmit}>
            <input
                className='prompt-input'
                disabled={props.isDisabled}
                type="text"
                placeholder='"Big first round upsets", "All 1 seeds in the final four", "I want to see a 16 seed win it all", etc.'
                value={value}
                onChange={handleChange}
            />
            <button
                className='prompt-button'
                disabled={props.isDisabled}
                type="submit"
            >
                {props.isDisabled ? Spinner : 'Generate'}
            </button>
        </form>
    );
};

export default PromptInput;