import React, { useState } from 'react';
import './App.css';
import logo from './static/logo.png';
import { Route, Routes, BrowserRouter, Link } from 'react-router-dom';
import MainPage from './pages/Main';
import PrivacyPage from './pages/Privacy';
import BracketPage from './pages/BracketPage';
import NoPage from './pages/NoPage';
import share from "./static/share.png"
import Modal from './components/Modal';

function App() {
  const [modal, setModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [copied, setCopied] = useState(false);

  const handleNewShareLink = (link: string) => {
    setShareLink(link);
  }

  const onCopy = (link: string) => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className="App-header">
          <Link className='hide-link' to="/">
            <div className="row-group">
              <img src={logo} className="App-logo" alt="logo" />
              <p className="App-logo-title">
                MadnessGPT
              </p>
            </div>
          </Link>
          {shareLink && (
            <div className="share-link-button" onClick={() => setModal(true)}>
              <img className='button-icon' src={share} alt="Share" />
              <p>Share</p>
            </div>
          )
          }
        </div>

        <Routes>
          <Route path="/" element={
            <MainPage onLink={(link: string) => handleNewShareLink(link)} />
          } />
          <Route path="/bracket/:id" element={
            <BracketPage onLink={(link: string) => handleNewShareLink(link)} />
          } />
          <Route path="/privacy" element={
            <PrivacyPage />
          } />
          <Route path="*" element={
            <NoPage />
          } />
        </Routes>

        <div className='footer'>
          <div className='footer-content'>
            <div className="left-align">
              <a className='App-link' href="https://github.com/nuckcrews/bracket-picker" target="_blank" rel="noreferrer">GitHub</a>
              <p>Powered by OpenAI's GPT-4</p>
            </div>
            <div className="right-align">
              <Link className='App-link' to="privacy">Privacy Policy</Link>
              <p>© 2024 MadnessGPT</p>
            </div>
          </div>
        </div>
        {modal && (
          <Modal onClose={() => setModal(false)}>
            <h2>Share Your Bracket!</h2>
            <div className='copy-to-clipboard-box'>
              <p className='small-link'>{shareLink}</p>
              <button
                className='copy-button'
                onClick={() => onCopy(shareLink)}
              >
                {copied ? "Copied!" : "Copy"}
              </button>
            </div>
          </Modal>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
