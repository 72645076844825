import { get } from 'aws-amplify/api';
import Tournament from '../models/Tournament';

interface Bracket {
    id: string;
    prompt: string;
    bracket: Tournament;
}

const BracketApi = {
    get: async (id: string): Promise<Bracket | undefined> => {
        try {
            const restOperation = get({
                apiName: 'BracketAPI',
                path: '/bracket?id=' + id,
            });
            const response = await restOperation.response;
            console.log('GET call succeeded: ', response);
            const model = await response.body.text();
            if (model) {
                return JSON.parse(model);
            } else {
                throw new Error('No bracket found');
            }
        } catch (e: any) {
            console.log('GET call failed: ', e);
            return undefined;
        }
    },
}

export default BracketApi;